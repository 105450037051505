<template>
  <v-card >
    <v-card-title :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-title card-header-tab card-header">

      <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
        {{ $t('settings.lang_nav_design') }}
      </div>
      <v-spacer/>
      <v-btn color="success" @click="updateLayout" :loading="loadingLayout">
        {{$t('generic.lang_save')}}
      </v-btn>
      <v-btn color="primary">
        {{$t('generic.lang_preview')}}
      </v-btn>
    </v-card-title>
    <v-divider class="pa-0 ma-0"/>
    <v-card-text class="ma-0 pa-0">
      <v-container class="ma-0 pa-0">
        <v-row no-gutters>
          <v-col>
            <template>
              <div role="tablist">
                <b-card no-body class="mb-1 transparent">
                  <b-card-header header-tag="header" v-b-toggle.accordion1 :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="p-1" role="tab">
                    <div class="pl-2 pr-2 d-block">
                      {{$t('localbee.lang_lbLogoAndHeaderImages')}}

                    </div>
                  </b-card-header>
                  <b-collapse id="accordion1" visible accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <v-row v-if="loading || loadingLayout" class="pa-5">
                        <v-col cols="12" class="text-center">
                          <v-progress-circular indeterminate color="primary"></v-progress-circular>
                          <p>{{$t('datatables.lang_Processing')}}</p>
                        </v-col>
                      </v-row>
                      <v-row v-if="!loading && !loadingLayout" class="pa-5">
                        <v-col cols="12" v-if="!loadingLayout" >
                          <v-select v-if="!loadingLayout" :label="$t('settings.lang_logoPlacement')" :items="[$t('generic.lang_left'),$t('generic.lang_centered'),$t('generic.lang_right')]" v-model="layout.logo.placement">
                          </v-select>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <div class="main-card mb-3 card shadow-sm" :class="[this.$vuetify.theme.dark? 'dark-bg' : '']">
                            <div class="pa-0 ma-0 dropdown-menu-header">

                              <div class="card-header bg-info text-white">
                                {{'Logo'}}
                              </div>

                            </div>
                            <div class="card-body">
                              <image-cropper ref="logo"
                                             v-model="logos.logo"/>
                            </div>
                            <div class="d-block text-right card-footer pa-0">
                              <v-btn :disabled="loaders.logo" :loading="loaders.logo"
                                     @click="upload(1)" block :class="[this.$vuetify.theme.dark? '' : 'text-dark']" class="btn bg-muted mx-auto mt-0 mb-0"
                                     large>
                                <v-icon class="ma-1">cloud_upload</v-icon>
                                {{this.$t('generic.lang_hochladen')}}
                              </v-btn>
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <div class="main-card mb-3 card shadow-sm" :class="[this.$vuetify.theme.dark? 'dark-bg' : '']">
                            <div class="pa-0 ma-0 dropdown-menu-header">

                              <div class="card-header bg-success text-white">
                                {{ $t('generic.lang_header_image') }}
                              </div>

                            </div>
                            <div class="card-body">
                              <image-cropper ref="header"
                                             v-model="logos.header"/>
                            </div>
                            <div class="d-block text-right card-footer pa-0">
                              <v-btn :disabled="loaders.header" :loading="loaders.header"
                                     @click="upload(2)" block :class="[this.$vuetify.theme.dark? '' : 'text-dark']" class="btn bg-muted mx-auto mt-0 mb-0"
                                     large>
                                <v-icon class="ma-1">cloud_upload</v-icon>
                                {{this.$t('generic.lang_hochladen')}}
                              </v-btn>
                            </div>
                          </div>
                        </v-col>

                      </v-row>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <b-card no-body class="mb-1 transparent">
                  <b-card-header header-tag="header" v-b-toggle.accordion2 :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="p-1" role="tab">
                    <div class="pl-2 pr-2 d-block">
                      {{ $t('generic.lang_navbar') }}
                    </div>
                  </b-card-header>
                  <b-collapse id="accordion2" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <v-row v-if="loadingLayout" class="pa-5">
                        <v-col cols="12" class="text-center">
                          <v-progress-circular indeterminate color="primary"></v-progress-circular>
                          <p>{{$t('datatables.lang_Processing')}}</p>
                        </v-col>
                      </v-row>
                      <v-row v-if="!loadingLayout">
                        <v-col cols="12">
                          <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard"
                                        v-model="layout.navbar.textSize" type="number" min="14" :rules="[
                                            (v)=>(!!v && Number(v)>=14)|| $t('generic.lang_sizeShouldBe14OrBigger')
                                        ]" :label="$t('settings.lang_fontSize')" value="14"/>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <div class="main-card mb-3 card shadow-sm">
                            <div class="pa-0 ma-0 dropdown-menu-header">

                              <div class="card-header bg-info text-white">
                                {{$t('generic.lang_backgroundcolor')}}
                              </div>
                            </div>
                            <div class="card-body pa-0">
                              <v-color-picker
                                  v-model="layout.navbar.backgroundColor"
                                  class="ma-0"
                                  width="100%"
                                  hide-canvas
                                  show-swatches
                                  swatches-max-height="100"
                              ></v-color-picker>
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <div class="main-card mb-3 card shadow-sm" :class="[this.$vuetify.theme.dark? 'dark-bg' : '']">
                            <div class="pa-0 ma-0 dropdown-menu-header">

                              <div class="card-header bg-info text-white">
                                {{ $t('generic.lang_textColor') }}
                              </div>
                            </div>
                            <div class="card-body pa-0">
                              <v-color-picker
                                  v-model="layout.navbar.textColor"
                                  class="ma-0"
                                  width="100%"
                                  hide-canvas
                                  show-swatches
                                  swatches-max-height="100"
                              ></v-color-picker>
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <b-card no-body class="mb-1 transparent">
                  <b-card-header header-tag="header" v-b-toggle.accordion3 :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="p-1" role="tab">
                    <div class="pl-2 pr-2 d-block">
                      {{ $t('generic.lang_buttons') }}
                    </div>
                  </b-card-header>
                  <b-collapse id="accordion3" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <v-row v-if="loadingLayout" class="pa-5">
                        <v-col cols="12" class="text-center">
                          <v-progress-circular indeterminate color="primary"></v-progress-circular>
                          <p>{{$t('datatables.lang_Processing')}}</p>
                        </v-col>
                      </v-row>
                      <v-row v-if="!loadingLayout">
                        <v-col cols="12">
                          <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard"
                                        v-model="layout.buttons.textSize" type="number" min="14" :rules="[
                                            (v)=>(!!v && Number(v)>=14)|| $t('generic.lang_sizeShouldBe14OrBigger')
                                        ]" :label="$t('settings.lang_fontSize')" value="14"/>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <div class="main-card mb-3 card shadow-sm">
                            <div class="pa-0 ma-0 dropdown-menu-header">

                              <div class="card-header bg-info text-white">
                                {{ $t('erp.lang_warecreate_color') }}
                              </div>
                            </div>
                            <div class="card-body pa-0">
                              <v-color-picker
                                  v-model="layout.buttons.backgroundColor"
                                  class="ma-0"
                                  width="100%"
                                  hide-canvas
                                  show-swatches
                                  swatches-max-height="100"
                              ></v-color-picker>
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <div class="main-card mb-3 card shadow-sm">
                            <div class="pa-0 ma-0 dropdown-menu-header">

                              <div class="card-header bg-info text-white">
                                {{ $t('generic.lang_textColor') }}
                              </div>
                            </div>
                            <div class="card-body pa-0">
                              <v-color-picker
                                  v-model="layout.buttons.textColor"
                                  class="ma-0"
                                  width="100%"
                                  hide-canvas
                                  show-swatches
                                  swatches-max-height="100"
                              ></v-color-picker>
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <b-card no-body class="mb-1 transparent">
                  <b-card-header header-tag="header" v-b-toggle.accordion4 :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="p-1" role="tab">
                    <div class="pl-2 pr-2 d-block">
                      {{ $t('generic.lang_cart') }}
                    </div>
                  </b-card-header>
                  <b-collapse id="accordion4" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <v-row v-if="loadingLayout" class="pa-5">
                        <v-col cols="12" class="text-center">
                          <v-progress-circular indeterminate color="primary"></v-progress-circular>
                          <p>{{$t('datatables.lang_Processing')}}</p>
                        </v-col>
                      </v-row>
                      <v-row v-if="!loadingLayout">
                        <v-col cols="12" sm="6">
                          <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard"
                                        v-model="layout.cart.textSize" type="number" min="14" :rules="[
                                            (v)=>(!!v && Number(v)>=14)|| $t('generic.lang_sizeShouldBe14OrBigger')
                                        ]" :label="$t('settings.lang_fontSize')" value="14"/>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard"
                                        v-model="layout.cart.textButtonsSize" type="number" min="14" :rules="[
                                            (v)=>(!!v && Number(v)>=14)|| $t('generic.lang_sizeShouldBe14OrBigger')
                                        ]" :label="$t('generic.lang_buttonsTextSize')" value="14"/>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <div class="main-card mb-3 card shadow-sm">
                            <div class="pa-0 ma-0 dropdown-menu-header">

                              <div class="card-header bg-info text-white">
                                {{$t('generic.lang_backgroundcolor')}}
                              </div>
                            </div>
                            <div class="card-body pa-0">
                              <v-color-picker
                                  v-model="layout.cart.backgroundColor"
                                  class="ma-0"
                                  width="100%"
                                  hide-canvas
                                  show-swatches
                                  swatches-max-height="100"
                              ></v-color-picker>
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <div class="main-card mb-3 card shadow-sm">
                            <div class="pa-0 ma-0 dropdown-menu-header">

                              <div class="card-header bg-info text-white">
                                {{ $t('generic.lang_textColor') }}
                              </div>
                            </div>
                            <div class="card-body pa-0">
                              <v-color-picker
                                  v-model="layout.cart.textColor"
                                  class="ma-0"
                                  width="100%"
                                  hide-canvas
                                  show-swatches
                                  swatches-max-height="100"
                              ></v-color-picker>
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <div class="main-card mb-3 card shadow-sm">
                            <div class="pa-0 ma-0 dropdown-menu-header">

                              <div class="card-header bg-info text-white">
                                {{ $t('generic.lang_buttonsColor') }}
                              </div>
                            </div>
                            <div class="card-body pa-0">
                              <v-color-picker
                                  v-model="layout.cart.buttonsColor"
                                  class="ma-0"
                                  width="100%"
                                  hide-canvas
                                  show-swatches
                                  swatches-max-height="100"
                              ></v-color-picker>
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <div class="main-card mb-3 card shadow-sm">
                            <div class="pa-0 ma-0 dropdown-menu-header">

                              <div class="card-header bg-info text-white">
                                {{ $t('generic.lang_buttonsTextColor') }}
                              </div>
                            </div>
                            <div class="card-body pa-0">
                              <v-color-picker
                                  v-model="layout.cart.textButtonsColor"
                                  class="ma-0"
                                  width="100%"
                                  hide-canvas
                                  show-swatches
                                  swatches-max-height="100"
                              ></v-color-picker>
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <b-card no-body class="mb-1 transparent">
                  <b-card-header header-tag="header" v-b-toggle.accordion5 :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="p-1" role="tab">
                    <div class="pl-2 pr-2 d-block">
                      {{ $t('generic.lang_dialogs') }}
                    </div>
                  </b-card-header>
                  <b-collapse id="accordion5" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <v-row v-if="loadingLayout" class="pa-5">
                        <v-col cols="12" class="text-center">
                          <v-progress-circular indeterminate color="primary"></v-progress-circular>
                          <p>{{$t('datatables.lang_Processing')}}</p>
                        </v-col>
                      </v-row>
                      <v-row v-if="!loadingLayout">
                        <v-col cols="12">
                          <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard"
                                        v-model="layout.dialogs.textSize" type="number" min="14" :rules="[
                                            (v)=>(!!v && Number(v)>=14)|| $t('generic.lang_sizeShouldBe14OrBigger')
                                        ]" :label="$t('settings.lang_fontSize')" value="14"/>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <div class="main-card mb-3 card shadow-sm">
                            <div class="pa-0 ma-0 dropdown-menu-header">

                              <div class="card-header bg-info text-white">
                                {{$t('generic.lang_backgroundcolor')}}
                              </div>
                            </div>
                            <div class="card-body pa-0">
                              <v-color-picker
                                  v-model="layout.dialogs.backgroundColor"
                                  class="ma-0"
                                  width="100%"
                                  hide-canvas
                                  show-swatches
                                  swatches-max-height="100"
                              ></v-color-picker>
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <div class="main-card mb-3 card shadow-sm">
                            <div class="pa-0 ma-0 dropdown-menu-header">

                              <div class="card-header bg-info text-white">
                                {{ $t('generic.lang_textColor') }}
                              </div>
                            </div>
                            <div class="card-body pa-0">
                              <v-color-picker
                                  v-model="layout.dialogs.textColor"
                                  class="ma-0"
                                  width="100%"
                                  hide-canvas
                                  show-swatches
                                  swatches-max-height="100"
                              ></v-color-picker>
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <b-card no-body class="mb-1 transparent">
                  <b-card-header header-tag="header" v-b-toggle.accordion6 :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="p-1" role="tab">
                    <div class="pl-2 pr-2 d-block">
                      {{$t('settings.lang_receiptSetFooter')}}
                    </div>
                  </b-card-header>
                  <b-collapse id="accordion6" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <v-row v-if="loadingLayout" class="pa-5">
                        <v-col cols="12" class="text-center">
                          <v-progress-circular indeterminate color="primary"></v-progress-circular>
                          <p>{{$t('datatables.lang_Processing')}}</p>
                        </v-col>
                      </v-row>
                      <v-row v-if="!loadingLayout">
                        <v-col cols="12">
                          <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard"
                                        v-model="layout.footer.textSize" type="number" min="14" :rules="[
                                            (v)=>(!!v && Number(v)>=14)|| $t('generic.lang_sizeShouldBe14OrBigger')
                                        ]" :label="$t('settings.lang_fontSize')" value="14"/>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <div class="main-card mb-3 card shadow-sm">
                            <div class="pa-0 ma-0 dropdown-menu-header">

                              <div class="card-header bg-info text-white">
                                {{$t('generic.lang_backgroundcolor')}}
                              </div>
                            </div>
                            <div class="card-body pa-0">
                              <v-color-picker
                                  v-model="layout.footer.backgroundColor"
                                  class="ma-0"
                                  width="100%"
                                  hide-canvas
                                  show-swatches
                                  swatches-max-height="100"
                              ></v-color-picker>
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <div class="main-card mb-3 card shadow-sm">
                            <div class="pa-0 ma-0 dropdown-menu-header">

                              <div class="card-header bg-info text-white">
                                {{ $t('generic.lang_textColor') }}
                              </div>
                            </div>
                            <div class="card-body pa-0">
                              <v-color-picker
                                  v-model="layout.footer.textColor"
                                  class="ma-0"
                                  width="100%"
                                  hide-canvas
                                  show-swatches
                                  swatches-max-height="100"
                              ></v-color-picker>
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </template>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                          :defaultKeySet="touchKeyboard.keySet"
                          :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                          :options="touchKeyboard.options" class="internalWidth"
                          id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
    </div>
  </v-card>
</template>

<script>
import mixin from "../../../mixins/KeyboardMixIns";
import ImageCropper from "../../common/imagecropper";
import {Events} from "../../../plugins/events";
import {ENDPOINTS} from '../../../config';


export default {
  name: "WebInterface",
  components: {ImageCropper},
  mixins: [mixin],
  data() {
    return {
      ENDPOINTS,
      loading: true,
      loadingLayout:true,

      logos: {
        header:null,
        logo:null,
      },
      loaders: {
        header:false,
        logo:false,
      },
      layout:{},

    }
  },
  methods: {
    getLogos() {
      this.loading = true;

      this.axios.post(ENDPOINTS.DELIVERY.SETTINGS.LOCALBEE.DESIGN.LOGOS.GET).then((res) => {
        if (res.status === 200) {
          this.logos.header = res.data.formfillData.textFields.header === 'data:image/;base64,' ? null : res.data.formfillData.textFields.header;
          this.logos.logo = res.data.formfillData.textFields.logo === 'data:image/;base64,' ? null : res.data.formfillData.textFields.logo;
        } else {
          Events.$emit("showSnackbar", {
            message: self.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: self.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(fin=>{
        this.loading = false;
      });
    },
    upload(type) {

      let logoType=(type===1)?'Logo':'Header';

      this.$refs[logoType.toLowerCase()].cropImage();
      this.loaders[logoType.toLowerCase()] = true;

      this.axios.post(ENDPOINTS.DELIVERY.SETTINGS.LOCALBEE.DESIGN.LOGOS.UPDATE, {
        logoUpload: this.logos[logoType.toLowerCase()],
        typeOfLogo: type,
      }).then((res) => {
        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_actionSuccessful'),
            color: "success"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: res.data.msg||res.data.status,
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loaders[logoType.toLowerCase()] = false;
      })
    },
    getLayout(){
      this.loadingLayout=true;

      this.axios.post(ENDPOINTS.DELIVERY.SETTINGS.LOCALBEE.DESIGN.LAYOUT.GET)
          .then((res) => {
            this.layout=res.data.formfillData.textFields.lb_layoutObj||{
              logo:{
                placement:'left',
              },
              navbar:{
                backgroundColor:null,
                textColor:null,
                textSize:null,
              },
              body:{
                backgroundColor:null,
                textColor:null,
                textSize:null,
              },
              footer:{
                backgroundColor:null,
                textColor:null,
                textSize:null,
              },
              buttons:{
                backgroundColor:null,
                textColor:null,
                textSize:null,
              },
              dialogs:{
                backgroundColor:null,
                textColor:null,
                textSize:null,
              },
              cart:{
                backgroundColor:null,
                textColor:null,
                textSize:null,
                buttonsColor:null,
                textButtonsColor:null,
                textButtonsSize:null,
              },
            }
          }).catch(err=>{
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(fin=>{
        this.loadingLayout=false;
      })
    },
    updateLayout(){
      this.loadingLayout=true;
      this.axios.post(ENDPOINTS.DELIVERY.SETTINGS.LOCALBEE.DESIGN.LAYOUT.UPDATE,{
        lb_layoutObj:this.layout,
      }).then((res) => {
        if(res.data.status==="SUCCESS"){
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_actionSuccessful'),
            color: "success"
          });
        }else {
          Events.$emit("showSnackbar", {
            message: res.data.msg||res.data.status,
            color: "error"
          });
        }
      }).catch(err=>{
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(fin=>{
        this.loadingLayout=false;
      })
    }
  },
  mounted() {
    this.getLayout();
    this.getLogos();

  },
}
</script>

<style scoped>
.dropdown-menu-header {
  z-index: 1 !important;
}
</style>
