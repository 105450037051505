<template>
  <v-card>
    <b-tabs content-class="mt-3">
      <b-tab :title="$t('settings.lang_emailSettings')" class="active">
        <KantinopelEmails/>
      </b-tab>
      <b-tab :title="$t('generic.lang_emailTemplates')" class="inactive">
        <EmailTemplates/>
      </b-tab>
    </b-tabs>
  </v-card>
</template>

<script>

import KantinopelEmails from "@/components/kantinopel/wedgets/KantinopelEmails";
import EmailTemplates from "@/components/kantinopel/wedgets/EmailTemplates";
export default {
  name: "EmailSettings",
  components: {EmailTemplates, KantinopelEmails}
}
</script>

<style scoped>

</style>
