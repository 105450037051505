<template>
  <v-card :loading="loading">
    <v-card-title>
      {{$t('generic.lang_canteenInformation')}}
    </v-card-title>
    <v-divider class="ma-0"/>
    <v-card-text>
      <v-container>
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col cols="12" lg="6" md="6" sm="6">
              <v-text-field v-model="canteen_displayedName" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                            :disabled="loading"
                            :rules="[rules.required]"
                            autocomplete="off"
                            dense
                            :label="$t('generic.lang_nameOfCanteenSchool')"
                            outlined
                            required
                            @focus="showTouchKeyboard"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="6">
              <v-text-field v-model="canteen_street" :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="loading"
                            :label="$t('localbee.lang_lbRestaurantStreet')"
                            :rules="[rules.required]"
                            autocomplete="off"
                            dense
                            outlined
                            required
                            @focus="showTouchKeyboard"

              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="6">
              <v-text-field v-model="canteen_houseNumber" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                            :disabled="loading"
                            :label="$t('localbee.lang_lbRestaurantStreetNo')"
                            :rules="[rules.required]"
                            autocomplete="off"
                            dense
                            outlined
                            required
                            @focus="showTouchKeyboard"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="6">
              <v-text-field v-model="canteen_zipCode" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                            :disabled="loading"
                            :label="$t('customers.lang_cust_zip')"
                            :rules="[rules.required]"
                            autocomplete="off"
                            dense
                            outlined
                            required
                            @focus="showTouchKeyboard"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="6">
              <v-text-field v-model="canteen_city" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                            :disabled="loading"
                            :label="$t('settings.lang_city')"
                            :rules="[rules.required]"
                            autocomplete="off"
                            dense
                            outlined
                            required
                            @focus="showTouchKeyboard"
              ></v-text-field>
            </v-col>
            <!--            <v-col cols="12" lg="6" md="6" sm="6">
                          <v-text-field v-model="email" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                        :disabled="loading"
                                        :label="$t('generic.lang_emailAdresse')"
                                        :rules="[rules.required]"
                                        autocomplete="off"
                                        dense
                                        outlined
                                        required
                                        @focus="showTouchKeyboard"
                          ></v-text-field>
                        </v-col>-->
            <v-col cols="12" lg="6" md="6" sm="6">
              <v-text-field v-model="canteen_phone" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                            :disabled="loading"
                            :label="$t('generic.lang_phoneNumber')"
                            :rules="[rules.required]"
                            autocomplete="off"
                            dense
                            outlined
                            type="number"
                            @focus="showTouchKeyboard"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" lg="6" md="6" sm="12">
              <v-textarea v-model="canteen_shortDesc" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                          :disabled="loading"
                          dense
                          :label="$t('generic.lang_briefDescriptionOfTheSchool_Canteen')"
                          outlined
                          @focus="showTouchKeyboard"
              >

              </v-textarea>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="12">
              <v-textarea v-model="canteen_fullDesc" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                          :disabled="loading"
                          dense
                          :label="$t('generic.lang_descriptionOfTheSchool_Canteen')"
                          outlined
                          @focus="showTouchKeyboard"
              >

              </v-textarea>
            </v-col>

            <v-col cols="12" lg="6" md="6" sm="12">
              <v-textarea v-model="canteen_imprintText" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                          :disabled="loading"
                          :label="$t('localbee.lang_lbImprintText')"
                          dense
                          outlined
                          @focus="showTouchKeyboard"
              >

              </v-textarea>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="12">
              <v-textarea v-model="canteen_emailFooterText" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                          :disabled="loading"
                          :label="$t('settings.lang_OptionalEmailFooterText')"
                          dense
                          outlined
                          @focus="showTouchKeyboard"
              >

              </v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
    <v-divider class="ma-0"/>
    <v-card-actions class="pa-1">
      <v-row>
        <v-col align="end" class="pt-0 pb-0" cols="12">
          <v-btn :disabled="!valid" :loading="loading" color="primary" elevation="0" large @click="update">
            {{ $t('generic.lang_save') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard"
                          :accept="hideTouchKeyboard"
                          :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet"
                          :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                          :options="touchKeyboard.options" class="internalWidth"/>
    </div>
  </v-card>
</template>

<script>
import mixin from "@/mixins/KeyboardMixIns";
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";

export default {
  name: "GlobalInformation",
  components: {},
  mixins: [mixin],
  data() {
    return {
      ENDPOINTS,
      rules: {
        required: (field) => {
          return ((field && field !== '' && field.toString().length > 0) || this.$t('generic.lang_requiredField'))
        },
      },
      loading: false,
      valid: false,
      canteen_displayedName: "",
      canteen_street: "",
      canteen_houseNumber: "",
      canteen_zipCode: "",
      canteen_city: "",
      canteen_phone: "",
      canteen_shortDesc: "",
      canteen_fullDesc: "",
      canteen_imprintText: "",
      canteen_emailFooterText: "",
    }
  },
  computed: {},
  methods: {
    getData() {
      this.loading = true;

      this.axios.post(ENDPOINTS.KANTINE.SETTINGS.GLOBALINFO.GET)
          .then(res => {
            this.canteen_displayedName = res.data.formfillData.textFields.canteen_displayedName;
            this.canteen_street = res.data.formfillData.textFields.canteen_street;
            this.canteen_houseNumber = res.data.formfillData.textFields.canteen_houseNumber;
            this.canteen_zipCode = res.data.formfillData.textFields.canteen_zipCode;
            this.canteen_city = res.data.formfillData.textFields.canteen_city;
            this.canteen_phone = res.data.formfillData.textFields.canteen_phone;
            this.canteen_shortDesc = res.data.formfillData.textFields.canteen_shortDesc;
            this.canteen_fullDesc = res.data.formfillData.textFields.canteen_fullDesc;
            this.canteen_imprintText = res.data.formfillData.textFields.canteen_imprintText,
                this.canteen_emailFooterText = res.data.formfillData.textFields.canteen_emailFooterText;
          }).catch(err => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(fin => {
        this.loading = false;
      })
    },
    update() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.loading = true;

      this.axios.post(ENDPOINTS.KANTINE.SETTINGS.GLOBALINFO.UPDATE, {
        canteen_displayedName: this.canteen_displayedName,
        canteen_street: this.canteen_street,
        canteen_houseNumber: this.canteen_houseNumber,
        canteen_zipCode: this.canteen_zipCode,
        canteen_city: this.canteen_city,
        canteen_phone: this.canteen_phone,
        canteen_shortDesc: this.canteen_shortDesc,
        canteen_fullDesc: this.canteen_fullDesc,
        canteen_imprintText: this.canteen_imprintText,
        canteen_emailFooterText: this.canteen_emailFooterText,
      }).then(res => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_updatedSuccessfully'),
          color: "success",
        });
      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      })
    },
  },
  mounted() {
    this.getData()
  }

}
</script>

<style scoped>

</style>