<template>
  <v-container fluid>
    <v-card :loading="loading">
      <v-card-title>
        {{ $t('generic.lang_paymentProcessing') }}
      </v-card-title>
      <v-divider class="ma-0"/>
      <v-card-text class="pa-0">
        <v-tabs v-model="tabs">
          <v-tab>
            Stripe
          </v-tab>

          <v-tab>
            Paypal
          </v-tab>

          <v-tab-item>
            <v-form ref="stripe_form" lazy-validation>
              <v-row class="pa-3">

                <v-col cols="12" sm="6">
                  <v-text-field v-model="stripeForm.stripe_key" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                :rules="[v => !!v]" dense
                                :label="'Stripe ' + $t('generic.lang_key')" outlined
                                @focus="showTouchKeyboard"/>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field v-model="stripeForm.stripe_secret" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                :rules="[v => !!v]" dense
                                :label="'Stripe ' + $t('generic.lang_secret')" outlined
                                @focus="showTouchKeyboard"/>
                </v-col>
                <v-col cols="12">
                  <v-checkbox v-model="stripeForm.stripe_enabled" :label="$t('generic.lang_enabled')"/>
                </v-col>
              </v-row>
            </v-form>
          </v-tab-item>

          <v-tab-item>
            <v-form ref="paypal_form" lazy-validation>
              <v-row class="pa-3">

                <v-col cols="12" sm="6">
                  <v-text-field v-model="paypal_form.paypal_id" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                :rules="[v => !!v]" dense
                                label="Paypal ID" outlined
                                @focus="showTouchKeyboard"/>
                </v-col>


                <v-col cols="12" sm="6">
                  <v-text-field v-model="paypal_form.paypal_secret_key" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                :rules="[v => !!v]" dense
                                :label="'Paypal ' + $t('settings.lang_secretKey')" outlined
                                @focus="showTouchKeyboard"/>
                </v-col>

                <v-col cols="12">
                  <v-checkbox v-model="paypal_form.paypal_enabled" :label="$t('generic.lang_enabled')"/>
                </v-col>
              </v-row>
            </v-form>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
      <v-card-actions class="border-top">
        <v-btn :disabled="loading" :loading="loading" block class="mx-auto" color="primary" elevation="0"
               @click="update(tabs === 0? 'stripe' : 'paypal')">
          {{ $t('generic.lang_save') }}
        </v-btn>
      </v-card-actions>

      <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
        <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard"
                            :accept="hideTouchKeyboard"
                            :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet"
                            :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                            :options="touchKeyboard.options" class="internalWidth"/>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import mixin from "@/mixins/KeyboardMixIns";
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";

export default {
  name: "KantinopelPaymentConfig",
  mixins: [mixin],
  data: () => {
    return {
      loading: false,
      tabs: 0,
      stripeForm: {
        paymentProvider: "stripe",
        stripe_key: "",
        stripe_secret: "",
        stripe_enabled: false
      },
      paypal_form: {
        paymentProvider: "paypal",
        paypal_id: "",
        paypal_secret_key: "",
        paypal_enabled: false
      }
    }
  },
  mounted() {
    this.getProviders();
  },
  methods: {
    getProviders() {
      this.loading = true;

      this.axios.post(ENDPOINTS.KANTINE.SETTINGS.PAYMENTS.GET)
          .then(res => {
            Object.assign(this.stripeForm, res.data.formfillData.textFields);
            Object.assign(this.paypal_form, res.data.formfillData.textFields);
          }).catch(err => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(fin => {
        this.loading = false;
      })
    },
    update(provider) {
      if (!this.$refs[provider + '_form'].validate()) {
        return;
      }
      this.loading = true;

      this.axios.post(ENDPOINTS.KANTINE.SETTINGS.PAYMENTS.UPDATE, provider === 'stripe' ? this.stripeForm : this.paypal_form).then(res => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_updatedSuccessfully'),
          color: "success",
        });
      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      })
    }
  }
}
</script>

<style scoped>

</style>
