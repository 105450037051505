<template>
  <v-container fluid>
    <v-row>
      <v-col class="pb-0" cols="12" md="3" sm="12">
        <div role="tablist">
          <b-card :class="[this.$vuetify.theme.dark? 'dark-bg' : '', 'mb-1']" no-body>
            <b-card-header class="p-0" header-tag="header" role="tab">
              <v-list-item v-b-toggle.global-settings class="ma-0"
                           @click="globalSettings =! globalSettings">
                <v-list-item-title :class="this.globalSettings? 'primary--text text-wrap ' : 'text-wrap'">
                  {{$t('generic.lang_canteenConfiguration')}}
                </v-list-item-title>
                <v-list-item-action>
                  <v-icon v-if="!this.globalSettings">keyboard_arrow_down</v-icon>
                  <v-icon v-if="this.globalSettings">keyboard_arrow_up</v-icon>
                </v-list-item-action>
              </v-list-item>
            </b-card-header>
            <b-collapse id="global-settings" :visible="this.globalSettings" accordion="global-settings"
                        role="tabpanel">
              <b-card-body class="pa-0">
                <b-list-group flush>
                  <b-list-group-item :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.currentTab === 'generalInfo'? 'primary--text' : '']"
                                     class="items"
                                     @click="handleClick('generalInfo')">
                    {{$t('generic.lang_canteenInformation')}}
                  </b-list-group-item>


                  <b-list-group-item :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.currentTab === 'logos'? 'primary--text' : '']"
                                     class="items"
                                     @click="handleClick('logos')">
                    Logos
                  </b-list-group-item>

                  <b-list-group-item :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.currentTab === 'email'? 'primary--text' : '']"
                                     class="items"
                                     @click="handleClick('email')">
                    {{$t('settings.lang_emailSettings')}}
                  </b-list-group-item>


<!--                  <b-list-group-item :class="this.currentTab === 'payments'? 'primary&#45;&#45;text' : ''"
                                     class="items"
                                     @click="handleClick('payments')">
                    Zahlungsabwicklung
                  </b-list-group-item>-->


                  <b-list-group-item :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.currentTab === 'interface'? 'primary--text' : '']"
                                     class="items"
                                     @click="handleClick('interface')">
                    {{ $t('generic.lang_Website') }}
                  </b-list-group-item>

                  <b-list-group-item :class="[this.$vuetify.theme.dark? 'dark-bg' : '', this.currentTab === 'globalSetting'? 'primary--text' : '']"
                                     class="items"
                                     @click="handleClick('globalSetting')">
                    {{ $t('generic.lang_globalSettings') }}
                  </b-list-group-item>
<!--                  <b-list-group-item :class="this.currentTab === 'messaging'? 'primary&#45;&#45;text' : ''"
                                     class="items"
                                     @click="handleClick('messaging')">
                    Nachrichten
                  </b-list-group-item>-->


                </b-list-group>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </v-col>


      <v-col cols="12" md="9" sm="12">

        <div v-if="currentTab === 'generalInfo'">
          <global-information/>
        </div>

        <div v-else-if="currentTab === 'logos'">
          <kantinopel-logos/>
        </div>
        <div v-else-if="currentTab === 'email'">
          <EmailSettings/>
        </div>

        <!--        <div v-else-if="currentTab === 'payments'">
                  <kantinopel-payment-config/>
                </div>-->

        <div v-else-if="currentTab === 'interface'">
          <WebInterface/>
        </div>

        <div v-else-if="currentTab === 'globalSetting'">
          <GlobalSettings/>
        </div>
<!--        <div v-else-if="currentTab === 'messaging'">
          <Messaging/>
        </div>-->

      </v-col>

    </v-row>
  </v-container>
</template>

<script>
import mixin from "@/mixins/KeyboardMixIns";
import Tabs from 'vue-tabs-with-active-line';
import GlobalInformation from "@/components/kantinopel/wedgets/GlobalInformation";
import KantinopelLogos from "@/components/kantinopel/wedgets/KantinopelLogos";
import KantinopelEmails from "@/components/kantinopel/wedgets/KantinopelEmails";
import KantinopelPaymentConfig from "@/components/kantinopel/wedgets/KantinopelPaymentConfig";
import GlobalSettings from "@/components/kantinopel/wedgets/GlobalSettings";
import EmailSettings from "@/components/kantinopel/wedgets/EmailSettings";
import WebInterface from "@/components/kantinopel/wedgets/WebInterface";
/*import Messaging from "@/components/kantinopel/wedgets/Messaging";*/

export default {
  name: "KantinopelSettingComponent",
  components: {
    /*Messaging,*/
    WebInterface,
    EmailSettings,
    GlobalSettings,
    KantinopelPaymentConfig,
    KantinopelLogos,
    GlobalInformation,
    Tabs
  },
  mixins: [mixin],
  data: () => {
    return {
      currentTab: 'generalInfo',
      globalSettings: true,
    }
  },
  methods: {
    handleClick(newTab) {
      this.currentTab = newTab;
    },
  },
  computed: {
    tabs: function () {
      return [
        {
          title: this.$t('generic.lang_basicData'),
          value: 'generalInfo',
        },
        {
          title: 'Logos',
          value: 'logos',
        }
      ];
    }
  }
}
</script>

<style scoped>
.tabs > .tabs__item, .tabs {
  width: auto !important;
  z-index: 1 !important;
}

.tabs__item_active, .tabs__active-line, .shadow-tabs .tab-item-line {
  padding-top: 20px !important;
}

.tabs__item {
  z-index: 1 !important;
}

.tabs__item:hover {
  border: none;
  outline: none;
}

.tabs__item:focus {
  border: none;
  outline: none;
}

.items {
  cursor: pointer !important;
}
</style>
