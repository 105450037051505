<template>
  <div>
    <page-title :heading="$t('settings.lang_settings')" :icon=icon :subheading="$t('settings.lang_settings')"
    ></page-title>
    <div class="app-main__inner">
      <kantinopel-setting-component/>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/Layout/Components/PageTitle";
import KantinopelSettingComponent from "@/components/kantinopel/KantinopelSettingComponent";


export default {
  name: "KantinopelSettings",
  components: {
    KantinopelSettingComponent,
    PageTitle,
  },
  data: () => ({
    icon: 'pe-7s-ribbon icon-gradient bg-tempting-azure',
  })
}
</script>