<template>
  <v-container class="pa-0">
    <!-- Beginning of Classes  -->
    <div v-if="this.classes">
      <v-card-title>
        <v-row no-gutters>
          <v-row justify="center">
            <v-dialog
                v-model="dialog"
                persistent
                max-width="600px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-col align="right">
                  <v-btn :loading="loading" color="primary" elevation="0"
                         v-bind="attrs"
                         v-on="on">
                    <v-icon class="ml-0 pl-0">
                      add
                    </v-icon>
                    {{ $t('generic.lang_create') }}
                  </v-btn>
                </v-col>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ $t('generic.lang_addNewClass') }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                            :label="$t('generic.lang_nameOfTheClass')"
                            required
                            v-model="classe.name"
                        ></v-text-field>
                      </v-col>
                      <v-col class="pl-2 pr-2" md="12" sm="6">
                        <swatches
                            inline v-model="classe.color"
                            background-color="transparent"
                        ></swatches>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDialog">
                    {{ $t('generic.lang_close') }}
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="createOrUpdateClass1">
                    {{ $t('generic.lang_save') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </v-row>
      </v-card-title>
      <Datatable
          :api-endpoint="ENDPOINTS.DATATABLES.KANTINE.CLASS"
          :datatable-headers="classHeaders"
          no-excel-export
          :show-header="false"
          :show-select-checkbox=false
          @editEntry="showDialog"
          @displayEntry="showEntry"
          @deleteEntry="deleteEntry"
          ref="class"
          show-delete-buttons
          show-edit-buttons
          show-display-buttons
          :permissionDelete="true"
          :permissionEdit="true"
          :loading="loading"
          id="1"

      />
    </div>
    <!-- End of Classes  -->

    <!-- Beginning of Groups  -->
    <div v-else>
      <v-card-title>
        <v-col align="left">{{nameOfSelectedClass}}</v-col>
        <v-row no-gutters>
          <v-row justify="center">
            <v-dialog
                v-model="dialog2"
                persistent
                max-width="600px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-col align="right">
                  <v-btn :loading="loading" color="primary" elevation="0"
                         v-bind="attrs"
                         v-on="on">
                    <v-icon class="ml-0 pl-0">
                      add
                    </v-icon>
                    {{ $t('generic.lang_create') }}
                  </v-btn>
                  <v-btn color="green" elevation="0" @click="back"
                         dark>
                    <v-icon class="ml-0 pl-0">
                      mdi-arrow-left
                    </v-icon>
                    {{ $t('generic.lang_prev') }}
                  </v-btn>
                </v-col>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ $t('generic.lang_addNewGroup') }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                            :label="$t('generic.lang_group')"
                            required
                            v-model="group.name"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDialog2">
                    {{ $t('generic.lang_close') }}
                  </v-btn>
                   <v-btn color="blue darken-1" text @click="createOrUpdateGroup2">
                                      {{ $t('generic.lang_save') }}
                                    </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </v-row>
      </v-card-title>
      <Datatable
          :api-endpoint="ENDPOINTS.DATATABLES.KANTINE.GROUP"
          :datatable-headers="groupHeaders"
          :data="this.params"
          no-excel-export
          :show-header="false"
          :show-select-checkbox=false
          @editEntry="showDialog2"
          @deleteEntry="deleteGroup"
          ref="groups"
          show-delete-buttons
          show-edit-buttons
          :permissionDelete="true"
          :permissionEdit="true"
          :loading="loading2"
      />
    </div>
    <!-- End of Groups  -->
  </v-container>
</template>

<script>
import mixin from "@/mixins/KeyboardMixIns";
import swatches from "vue-swatches"
import {ENDPOINTS} from "@/config";
import FontawesomePicker from "@/components/common/iconPicker";
import Datatable from "@/components/datatable/Datatable";
import {Events} from "@/plugins/events";
export default {
  name: "ClassSettings",
  components: {
    Datatable,
    FontawesomePicker,
    swatches,
  },
  mixins: [mixin],
  watch: {
    classes(val) {
      const self = this;
      if (!val) {
        let timer = setImmediate(() => {
          console.log(self.$refs.groups.getDataFromApi())
          this.nameOfSelectedClass = this.selected.name;
          clearTimeout(timer)
        }, 500)
      } else {
        let timer = setImmediate(() => {
          console.log(self.$refs.class.getDataFromApi())
          this.nameOfSelectedClass = "";
          clearTimeout(timer)
        }, 500)
      }
    }
  },
  data() {
    return {
      selected: {},
      ENDPOINTS,
      loading: false,
      classes: true,
      shortNameValid: false,
      dialog: false,
      dialog2: false,
      loading2: false,
      editDialog1 : false,
      editDialog2 : false,
      nameOfSelectedClass : false,
      url: null,
      data: [],
      classe: {
        uuid: null,
        name: null,
        color: null,
      },
      group: {
        uuid: null,
        name: null,
        color: null,
      },
      classHeaders: [
        {
          text: 'uuid',
          value: 'id',
          width: 100,
          hide: true,
        },
        {
          text: this.$t('generic.lang_nameOfTheClass'),
          value: 'name'
        },
        {
          text: this.$t('erp.lang_warecreate_color'),
          value: 'color'
        },

      ],
      groupHeaders: [
        {
          text: 'uuid',
          value: 'id',
          width: 100,
          hide: true,
        },
        {
          text: this.$t('generic.lang_groupName'),
          value: 'name'
        },

      ],

    }
  },
  computed: {
    params() {
      return {
        class_uuid: this.selected.id
      }
    },
  },
  methods: {
    deleteEntry(item) {
      this.loading = true;
      this.axios.post(ENDPOINTS.KANTINE.SETTINGS.CLASS.DELETE, {
        classesUUIDs: [item.id],
      }).then((res) => {
        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_actionSuccessful'),
            color: "success"
          });
          this.loading = false;
          this.$refs.class.getDataFromApi();

        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
        this.dialog = false;
      })
    },
    sendData() {
      this.loading = true;
    //  //console.log("");
      this.axios.post(ENDPOINTS.KANTINE.SETTINGS.CLASS.CREATE, {
        name: this.classe.name,
        color: this.classe.color
      }).then((res) => {
        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_actionSuccessful'),
            color: "success"
          });
          this.loading = false;
          this.$refs.class.getDataFromApi();
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
        this.dialog = false;
        this.closeDialog()
      })
    },
    deleteGroup(item) {
      this.loading = true;
      this.axios.post(ENDPOINTS.KANTINE.SETTINGS.GROUP.DELETE, {
        groupUUIDs: [item.id],
      }).then((res) => {
        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_actionSuccessful'),
            color: "success"
          });
          this.loading = false;
          this.$refs.groups.getDataFromApi();
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
        this.dialog = false;
      })
    },
    createGroup(id) {
      this.loading = true;
      this.axios.post(ENDPOINTS.KANTINE.SETTINGS.GROUP.CREATE,{
        uuid: this.selected.id,
        name : this.group.name
      }).then((res) => {if (res.data.status === 'SUCCESS') {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_actionSuccessful'),
          color: "success"
        });
        this.loading = false;
        this.$refs.groups.getDataFromApi();
      }
        else {

        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
        this.closeDialog2();
      })
    },
    updateGroup(id) {
      this.loading = true;
      this.axios.post(ENDPOINTS.KANTINE.SETTINGS.GROUP.UPDATE, {
        classUUID : this.selected.id,
        name : this.group.name,
        uuid : this.group.uuid,
      }).then((res) => {
        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_actionSuccessful'),
            color: "success"
          });
          this.loading = false;
          this.$refs.groups.getDataFromApi();
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
        this.dialog2 = false;
        this.closeDialog2();
      })
    },
    updateClasses(id) {
      this.loading = true;
      this.axios.post(ENDPOINTS.KANTINE.SETTINGS.CLASS.UPDATE+"?uuid="+this.classe.uuid, {
        name: this.classe.name,
        color: this.classe.color
      }).then((res) => {
        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_actionSuccessful'),
            color: "success"
          });
          this.loading = false;
          this.$refs.class.getDataFromApi();
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
        this.dialog = false;
        this.closeDialog();
      })
    },
    showDialog2(item) {
      this.dialog2 = true;
      this.editDialog2 = true;
      this.group.uuid = item.id
      this.group.name = item.name
    },
    closeDialog2() {
      this.dialog2 = false;
      this.group.uuid = null
      this.group.name = null
      this.editDialog2 = false ;
    },
    showDialog(item) {
      this.dialog = true;
      this.classe.uuid = item.id
      this.classe.name = item.name
      this.classe.color = item.color
      this.editDialog = true ;

    },
    showEntry(item) {
      this.selected = Object.assign({}, item);
      console.log(this.selected.id)
      console.log(this.selected.name)
      this.classes = false;
      this.dialog = false;
      let timer = setImmediate(() => {
        console.log(
            this.$refs.groups.getDataFromApi())
            this.nameOfSelectedClass = this.selected.name;
        clearTimeout(timer)
      }, 1000)
    },
    closeDialog() {
      this.dialog = false;
      this.classe.uuid = null;
      this.classe.name = null;
      this.classe.color = null;
      this.editDialog = false;
    },
    createOrUpdateGroup2() {
      this.dialog2 = false;
      if(this.editDialog2)
      {
        this.updateGroup()
      }
      else
      {
        this.createGroup()
      }

    },
    createOrUpdateClass1() {
      this.dialog = false;
      if(this.editDialog)
      {
        this.updateClasses()
      }
      else
      {
        this.sendData()
      }

    },
    back() {
      this.dialog = false;
      this.classes = true;
      let timer = setImmediate(() => {
        this.nameOfSelectedClass = "";

        clearTimeout(timer)
      }, 1000)


    },
  }
}
</script>
<style scoped>

</style>