<template>
  <v-card>
    <b-tabs content-class="mt-3">
      <b-tab :title="'LOGO'" class="active">
        <v-card :loading="loading" elevation="0">
          <v-card-text>
            <v-container>
              <div class="card-body pa-0">
                <v-row v-if="loadingLogos" class="pa-5">
                <v-col cols="12" class="text-center">
                  <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  <p>{{$t('datatables.lang_Processing')}}...</p>
                </v-col>
              </v-row>
                <v-row v-if="!loadingLogos" align="center" class="pa-5" justify="center">
                  <!--new logos-->

                  <v-col cols="12" sm="6">
                    <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="main-card mb-3 card border border shadow-none">
                      <div class="pa-0 ma-0 dropdown-menu-header">

                        <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-header bg-muted text-dark">
                          {{ $t('generic.lang_favicon') }}
                        </div>

                      </div>
                      <div class="card-body">
                        <image-cropper ref="favicon"
                                       v-model="logos.favicon" @update="updateImage('favicon')"/>
                      </div>
                      <div class="d-block text-right card-footer pa-0">
                        <v-btn :disabled="loaders.favicon" :loading="loaders.favicon"
                               block :class="[this.$vuetify.theme.dark? '' : 'text-dark']" class="btn bg-muted mx-auto mt-0 mb-0" large
                               @click="upload('favicon')">
                          <v-icon class="ma-1">cloud_upload</v-icon>
                          {{ this.$t('generic.lang_hochladen') }}
                        </v-btn>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="main-card mb-3 card border shadow-none">
                      <div class="pa-0 ma-0 dropdown-menu-header">

                        <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-header bg-muted text-dark">
                          {{ $t('generic.lang_smallLogo') }}
                        </div>

                      </div>
                      <div class="card-body">
                        <image-cropper ref="smallLogo"
                                       v-model="logos.smallLogo" @update="updateImage('smallLogo')"/>
                      </div>
                      <div class="d-block text-right card-footer pa-0">
                        <v-btn :disabled="loaders.smallLogo" :loading="loaders.smallLogo"
                               block :class="[this.$vuetify.theme.dark? '' : 'text-dark']" class="btn bg-muted mx-auto mt-0 mb-0" large
                               @click="upload('smallLogo')">
                          <v-icon class="ma-1">cloud_upload</v-icon>
                          {{ this.$t('generic.lang_hochladen') }}
                        </v-btn>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="main-card mb-3 card border shadow-none">
                      <div class="pa-0 ma-0 dropdown-menu-header">

                        <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-header bg-muted text-dark">
                          {{ $t('generic.lang_largeLogo') }}
                        </div>

                      </div>
                      <div class="card-body">
                        <image-cropper ref="largeLogo"
                                       v-model="logos.largeLogo" @update="updateImage('largeLogo')"/>
                      </div>
                      <div class="d-block text-right card-footer pa-0">
                        <v-btn :disabled="loaders.largeLogo" :loading="loaders.largeLogo"
                               block :class="[this.$vuetify.theme.dark? '' : 'text-dark']" class="btn bg-muted mx-auto mt-0 mb-0" large
                               @click="upload('largeLogo')">
                          <v-icon class="ma-1">cloud_upload</v-icon>
                          {{ this.$t('generic.lang_hochladen') }}
                        </v-btn>
                      </div>
                    </div>
                  </v-col>
                  <!--end new logos-->
                </v-row>
              </div>

              <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
                <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard"
                                    :accept="hideTouchKeyboard"
                                    :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet"
                                    :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                                    :options="touchKeyboard.options" class="internalWidth"/>
              </div>
            </v-container></v-card-text></v-card>
      </b-tab>
      <b-tab :title="$t('generic.lang_image')" class="inactive">
        <KantinopelBackground/>
      </b-tab>
      <b-tab :title="$t('generic.lang_card')" class="inactive">
        <v-card :loading="loading" elevation="0">
          <v-card-title v-if="cardImagePath" class="border_all">
            <v-img :src="cardImagePath" max-height="220" width="100%" height="200" contain class="mx-auto" />
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" lazy-validation v-model="valid" style="width: 100%;">
              <v-col class="pt-0 mt-0" cols="12">
                <v-file-input v-model="cardImage" accept="image/*" type="file" append-icon="add_photo_alternate" dense
                              :label="$t('generic.lang_front')" :loading="loading" chips :rules="[v=>!!v||'']"
                              outlined/>
              </v-col>
              </v-form>
            </v-container>
          </v-card-text>
          <v-divider class="ma-0"/>
          <v-card-actions class="d-flex justify-end">
            <v-btn :disabled="loading||!valid" :loading="loading" class="ma-0 bg-primary text-light"
                   large
                   @click="uploadCardImage('f')">
              {{$t('generic.lang_save')}}
            </v-btn>
          </v-card-actions>
          <v-divider class="ma-0"/>
          <v-card-title v-if="this.cardImagePathBack" class="border_all">
            <v-img :src="this.cardImagePathBack" max-height="220" height="200" contain class="mx-auto" />
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" lazy-validation v-model="validBack" style="width: 100%;">
              <v-col class="pt-0 mt-0" cols="12">
                <v-file-input v-model="cardImageBack" accept="image/*" type="file" append-icon="add_photo_alternate" dense
                              :label="$t('generic.lang_move')" :loading="loading" chips :rules="[v=>!!v||'']"
                              outlined/>
              </v-col>
              </v-form>
            </v-container>
          </v-card-text>
          <v-divider class="ma-0"/>
          <v-card-actions class="d-flex justify-end">
            <v-btn :disabled="loading||!validBack" :loading="loading" class="ma-0 bg-primary text-light"
                   large
                   @click="uploadCardImage('b')">
              {{$t('generic.lang_save')}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </b-tab>
    </b-tabs>
  </v-card>
</template>


<script>
import {ENDPOINTS} from '@/config'
import mixin from "@/mixins/KeyboardMixIns";
import ImageCropper from "@/components/common/imagecropper";
import KantinopelBackground from "../wedgets/kantinopellBackground";

import {Events} from "@/plugins/events";

export default {
  name: "KantinopelLogos",
  components: {ImageCropper,KantinopelBackground},
  mixins: [mixin],
  data() {
    return {
      ENDPOINTS,
      loading: false,
      loadingLogos: false,
      updateFlags: {
        smallLogo: false,
        largeLogo: false,
        favicon: false,
      },
      logos: {
        smallLogo: null,
        largeLogo: null,
        favicon: null,
      },
      loaders: {
        smallLogo: null,
        largeLogo: null,
        favicon: null,
      },
      types: {
        smallLogo: 2,
        largeLogo: 3,
        favicon: 1,
      },
      cardImage:null,
      cardImageBack:null,
      cardImagePath:'',
      cardImagePathBack:'',
      valid:false,
      validBack:false,
    }
  },
  watch:{
    'logos.favicon':function (v) {
      this.logos.favicon=v;
    }
  },
  methods: {
    updateImage(type){
      this.updateFlags[type]=true;
    },
    async uploadCardImage(face) {
      if(face==='f')
        if (!this.$refs.form.validate()) return;
      else
        if (!this.$refs.form.validate()) return;
      this.loading = true;

      let img=(face==='f'?this.cardImage:this.cardImageBack);
      let f=new FormData();
      await this.getBase64(img).then(data=>f.append((face==='f'?'cardImageFront':'cardImageBack'),data));
      this.axios.post(ENDPOINTS.KANTINE.SETTINGS.CARD.UPDATE, f)
          .then((res) => {
            if (res.data.status === "SUCCESS") {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_success'),
                color: "success"
              });
              this.getCardData();
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.msg,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      })
    },
    getCardData() {
      this.loading = true;

      this.axios.post(ENDPOINTS.KANTINE.SETTINGS.CARD.GET, {}).then((res) => {
        if (res.status === 200) {
          this.cardImagePath=res.data.cardImageFront;
          this.cardImagePathBack=res.data.cardImageBack;
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(()=>{
        this.loading = false;
      })
    },
    getData() {
      this.loadingLogos = true;

      this.axios.post(ENDPOINTS.KANTINE.SETTINGS.LOGOS.GET, {}).then((res) => {
        if (res.status === 200) {
          this.logos.smallLogo = res.data.formfillData.logos.canteen_logoSmall === 0 ? null : res.data.formfillData.logos.canteen_logoSmall;
          this.logos.largeLogo = res.data.formfillData.logos.canteen_logoLarge === 0 ? null : res.data.formfillData.logos.canteen_logoLarge;
          this.logos.favicon = res.data.formfillData.logos.canteen_favIcon === 0 ? null : res.data.formfillData.logos.canteen_favIcon;
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });

          this.loading = false;
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(()=>{
        this.loadingLogos=false;
      })
    },
    upload(logoType) {
      this.$refs[logoType].cropImage();
      this.loaders[logoType] = true;

      this.axios.post(ENDPOINTS.KANTINE.SETTINGS.LOGOS.UPDATE, {
        logoUpload: this.logos[logoType],
        typeOfLogo: this.types[logoType],

      }).then((res) => {
        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_actionSuccessful'),
            color: "success"
          });

        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loaders[logoType] = false;
        this.updateFlags[logoType]= false;
      })
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
  },
  mounted() {
    this.getData();
    this.getCardData();
  },
}
</script>

<style scoped>
.dropdown-menu-header {
  z-index: 1 !important;
}
</style>