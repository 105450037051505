<template>
  <v-card elevation="0">
    <v-card-text>
      <v-container>
        <v-row v-if="loading" class="pa-5">
        <v-col cols="12" class="text-center">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
          <p>{{$t('datatables.lang_Processing')}}...</p>
        </v-col>
        </v-row>
        <v-row v-if="!loading" align="center" class="pa-5" justify="center">
          <!--new logos-->
          <v-col cols="12" sm="6">
            <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="main-card mb-3 card border shadow-none">
              <div class="pa-0 ma-0 dropdown-menu-header">

                <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-header bg-muted text-dark">
                  {{ $t('generic.lang_image') }}
                </div>

              </div>
              <div class="card-body">
                <image-cropper ref="backgroundImage" @update="updateImage('backgroundImage')"
                               v-model="logos.backgroundImage"/>
              </div>
              <div class="d-block text-right card-footer pa-0">
                <v-btn :disabled="loaders.backgroundImage" :loading="loaders.backgroundImage"
                       block :class="[this.$vuetify.theme.dark? '' : 'text-dark']" class="btn bg-muted mx-auto mt-0 mb-0" large
                       @click="upload('backgroundImage')">
                  <v-icon class="ma-1">cloud_upload</v-icon>
                  {{ this.$t('generic.lang_hochladen') }}
                </v-btn>
              </div>
            </div>
          </v-col>

          <v-col cols="12" sm="6">
            <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="main-card mb-3 card border shadow-none">
              <div class="pa-0 ma-0 dropdown-menu-header">

                <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-header bg-muted text-dark">
                  {{ $t('generic.lang_canteenNote') }}
                </div>

              </div>
              <div class="card-body">
                <image-cropper ref="menuNote" @update="updateImage('menuNote')"
                               v-model="logos.menuNote"/>
              </div>
              <div class="d-block text-right card-footer pa-0">
                <v-btn :disabled="loaders.menuNote" :loading="loaders.menuNote"
                       block :class="[this.$vuetify.theme.dark? '' : 'text-dark']" class="btn bg-muted mx-auto mt-0 mb-0" large
                       @click="upload('menuNote')">
                  <v-icon class="ma-1">cloud_upload</v-icon>
                  {{ this.$t('generic.lang_hochladen') }}
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
        <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
          <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard"
                              :accept="hideTouchKeyboard"
                              :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet"
                              :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                              :options="touchKeyboard.options" class="internalWidth"/>
        </div>
      </v-container>
    </v-card-text>
  </v-card>
</template>


<script>
import {ENDPOINTS} from '@/config'
import mixin from "@/mixins/KeyboardMixIns";
import ImageCropper from "@/components/common/imagecropper";
import {Events} from "@/plugins/events";

export default {
  name: "KantinopelBackground",
  components: {ImageCropper},
  mixins: [mixin],
  data() {
    return {
      ENDPOINTS,
      loading: false,
      updateFlags: {
        backgroundImage: false,
        menuNote : false
      },
      logos: {
        backgroundImage: "",
        menuNote : ""
      },
      loaders: {
        backgroundImage: null,
        menuNote : null
      },
      types: {
        backgroundImage: 1,
        menuNote : 2,
      }
    }
  },
  methods: {
    updateImage(type){
      this.updateFlags[type]=true;
    },
    getData() {
      this.loading = true;

      this.axios.post(ENDPOINTS.KANTINE.SETTINGS.IMAGES.GET, {}).then((res) => {
        if (res.status === 200) {

          this.logos.menuNote = res.data.formfillData.logos.canteen_menuNote === 0 ? null : res.data.formfillData.logos.canteen_menuNote;

          this.logos.backgroundImage = res.data.formfillData.logos.canteen_background === 0 ? null : res.data.formfillData.logos.canteen_background;
          this.loading = false;
        } else {
          Events.$emit("showSnackbar", {
            message: self.$t('generic.lang_errorOccurred'),
            color: "error"
          });

          this.loading = false;
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: self.$t('generic.lang_errorOccurred'),
          color: "error"
        });
        this.loading = false;
      });
    },
    upload(logoType) {
      this.$refs[logoType].cropImage();
      this.loaders[logoType] = true;

      this.axios.post(ENDPOINTS.KANTINE.SETTINGS.IMAGES.UPDATE, {
        logoUpload: this.logos[logoType],
        typeOfLogo: this.types[logoType],
      }).then((res) => {
        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_actionSuccessful'),
            color: "success"
          });

        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loaders[logoType] = false;
      })
    }
  },
  mounted() {
    this.getData();
  },
}
</script>

<style scoped>
.dropdown-menu-header {
  z-index: 1 !important;
}
</style>
